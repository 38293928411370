<template>
	<div class="register-details">
		<div class="head-title">完善信息</div>
		<div class="head-text">请实名认证，完善信息后才能参加活动</div>
		<div class="head-text text-color" @click="toDistrict">如需要注册到信州区，请点击此文字。</div>
		<div class="middle-item"><input type="text" v-model="name" placeholder="请输入姓名" /></div>
		<div class="middle-item">
			<input type="text" v-model="idCard" placeholder="请输入身份证号" />
		</div>
		<!-- <div class="middle-item">
			<input type="text" @click="isShowDistrictSelect = true" readonly placeholder="请选择县市区"
				v-model="districtText" />
			<div class="middle-item-right">
				<van-icon name="arrow-down" size="20px" color="#999" />
			</div>
		</div>
		<div class="middle-item">
			<input type="text" @click="showDepartmentSelect" v-model="departmentText" readonly placeholder="请选择中队" />
			<div class="middle-item-right">
				<van-icon name="arrow-down" size="20px" color="#999" />
			</div>
		</div> -->
		<div class="middle-item">
			<input type="text" @click="isCascaderShow = !isCascaderShow" readonly placeholder="请选择所属队伍"
				v-model="cascaderText" />
			<div class="middle-item-right">
				<van-icon name="arrow-down" size="20px" color="#999" />
			</div>
		</div>
		<div class="middle-item-radio">
			<div class="item-radio-text">是否党员</div>
			<div>
				<van-radio-group v-model="party_member" direction="horizontal">
					<van-radio name="1">是</van-radio>
					<van-radio name="2">否</van-radio>
				</van-radio-group>
			</div>
		</div>
		<div class="middle-item"><input type="text" v-model="company" placeholder="请填写工作单位" /></div>
		<div class="middle-item-bottom">
			<van-checkbox v-model="agreement" shape="square">
				<span class="agree-text">我已阅读并同意</span>
				<span class="agree-protocol" @click.stop="$router.push({ name: 'RegisterNotice' })">加入须知</span>
			</van-checkbox>
		</div>
		<div class="footer">
			<van-button type="primary" color="#3377FF" plain round block @click="$parent.step = 2">
				上一步
			</van-button>
			<van-button type="primary" color="#3377FF" round block @click="onSubmit">
				注册
			</van-button>
		</div>
		
		<van-popup v-model="isShowProtocol" class="treaty-popup" closeable>
			<register-notice></register-notice>
		</van-popup>
		
		<van-popup v-model="isShowDistrictSelect" position="bottom">
			<van-picker show-toolbar value-key="district_name" :columns="districtData" @confirm="onConfirmDistrict"
				@cancel="isShowDistrictSelect = false" />
		</van-popup>

		<van-popup v-model="isShowDepartmentSelect" position="bottom">
			<van-picker show-toolbar value-key="department_name" :columns="departmentData"
				@confirm="onConfirmDepartment" @cancel="isShowDepartmentSelect = false" />
		</van-popup>

		<van-popup v-model="isCascaderShow" position="bottom" :close-on-click-overlay="false">
			<van-cascader v-model="cascaderText" title="请选择所属队伍" :options="cascaderList"
				@close="onClose" @finish="onCascaderChange" />
		</van-popup>
	</div>
</template>

<script>
	import IndexApi from '@/api/index';
	import VantVendor from '@/vendor/vant';
	import ValidatorVendor from '@/vendor/validator';
	import RegisterNotice from '@/views/register/RegisterNotice.vue'
	import ActionVendor from "@/vendor/action"
	
	export default {
		name: 'RegisterDetail',
		data() {
			return {
				isCascaderShow: false,
				cascaderText: '',
				cascaderList: [],
				

				name: '',
				idCard: '',
				districtId: '',
				agreement: true,
				departmentId: '',
				isShowProtocol: false,
				districtText: '',
				departmentText: '',
				isShowDistrictSelect: false,
				isShowDepartmentSelect: false,
				districtData: [],
				departmentData: [],
				validator: null,
				party_member: '2',
				company: "",
				
				level_1_id: '',
				level_2_id: 0,
				level_3_id: 0,
				level_4_id: 0,
			};
		},
		components: {
			RegisterNotice,
		},
		created() {
			VantVendor.Toast.loading('加载中');
			
			this.name = this.$parent.userInfo.name;
			this.idCard = this.$parent.userInfo.idCard;
			this.districtId = this.$parent.userInfo.districtId;
			this.departmentId = this.$parent.userInfo.departmentId;
			this.company = this.$parent.userInfo.company;
			IndexApi.departmentsTree()
				.then(result => {
					this.cascaderList = result.data;
					
					// if (!ValidatorVendor.checkValue('isEmpty', this.departmentId)) {
						// this.setDistrictText(this.districtId);
						// this.setDepartmentText(this.departmentId);
					// }

					VantVendor.Toast.clear();
				})
				.catch(error => {
					console.log(error);
				});

			this.validator = new ValidatorVendor({
				rules: {
					name: {
						required: true
					},
					id_card: {
						idcard: true,
						required: true
					},
					level_1_id: {
						required: true,
						min: 1
					},
					company: {
						required: true,
					}
				},
				messages: {
					name: {
						required: '姓名不能为空'
					},
					id_card: {
						idcard: '身份证号格式错误',
						required: '身份证号不能为空'
					},
					level_1_id: {
						required: '请选择所属队伍',
					},
					company: {
						required: '工作单位不能为空',
					},
				}
			});
		},
		methods: {
			toDistrict(){
				ActionVendor.jumpArea()
			},
			onSubmit() {
				if(typeof(this.cascaderText) == 'number'){
					return VantVendor.Toast('所属队伍选择错误');
				}
				let params = {
					name: this.name,
					id_card: this.idCard,
					
					district_id: this.districtId,
					department_id: this.departmentId,
					
					level_1_id: this.level_1_id,
					level_2_id: this.level_2_id,
					level_3_id: this.level_3_id,
					level_4_id: this.level_4_id,
					
					company: this.company,
					party_member: this.party_member
				};

				if (!this.validator.checkData(params)) {
					return;
				}
				if (this.agreement == false) {
					return VantVendor.Toast('请勾选加入须知');
				}
				
				VantVendor.Toast.loading('加载中');

				IndexApi.registerUserInfo(params)
					.then(result => {
						VantVendor.Toast.clear();
						this.$store.commit('district/setDistrict', result.data);
						this.$emit('success', params);
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			onConfirmDistrict(selected) {
				this.districtId = selected.district_id;
				this.districtText = selected.district_name;
				this.isShowDistrictSelect = false;
				this.departmentData = selected.child;
				this.departmentText = '';
				this.departmentId = '';
			},
			onConfirmDepartment(selected) {
				this.departmentId = selected.department_id;
				this.departmentText = selected.department_name;
				this.isShowDepartmentSelect = false;
			},
			setDistrictText(districtId) {
				let index = this.districtData.findIndex(e => {
					return e.district_id == districtId;
				});

				this.districtText = this.districtData[index].department_name;
				this.departmentData = this.districtData[index].child;
			},
			setDepartmentText(departmentId) {
				let index = this.departmentData.findIndex(e => {
					return e.department_id == departmentId;
				});

				this.departmentText = this.departmentData[index].department_name;
			},
			showDepartmentSelect() {
				if (ValidatorVendor.checkValue('isEmpty', this.districtId)) {
					return VantVendor.Toast('请先选择市区');
				}
				this.isShowDepartmentSelect = true;
			},
			onCascaderChange(e) {
				let options = e.selectedOptions
				this.isCascaderShow = !this.isCascaderShow
				
				this.districtId = options[0].district_id
				if(options.length == 1){
					this.cascaderText = options[0].text
					this.level_1_id = options[0].value
					this.level_2_id = 0
					this.level_3_id = 0
					this.level_4_id = 0
					
					this.departmentId = options[0].value
				}else if(options.length == 2){
					this.cascaderText = options[0].text + '-' + options[1].text
					this.level_1_id = options[0].value
					this.level_2_id = options[1].value
					this.level_3_id = 0
					this.level_4_id = 0
					
					this.departmentId = options[1].value
				}else if(options.length == 3){
					this.cascaderText = options[0].text + '-' + options[1].text + '-' + options[2].text
					this.level_1_id = options[0].value
					this.level_2_id = options[1].value
					this.level_3_id = options[2].value
					this.level_4_id = 0
					
					this.departmentId = options[2].value
				}else if(options.length == 4){
					this.cascaderText = options[0].text + '-' + options[1].text + '-' + options[2].text + '-' + options[3].text
					this.level_1_id = options[0].value
					this.level_2_id = options[1].value
					this.level_3_id = options[2].value
					this.level_4_id = options[3].value
					
					this.departmentId = options[3].value
				}
			},
			onClose(){
				this.isCascaderShow = false
				this.cascaderText = ''
			}
		}
	};
</script>

<style lang="less" scoped>
	.register-details {
		background-color: #fff;
		padding: 20px 30px;
		height: 100%;
		box-sizing: border-box;

		.treaty-popup {
			border-radius: 5px;
		}

		.treaty {
			height: 70vh;
			width: 85vw;
			padding: 10px 0;
			box-sizing: border-box;

			.treaty-head-title {
				width: 100%;
				height: 40px;
				line-height: 40px;
				text-align: center;
				font-size: 18px;
				border-bottom: 1px solid #eee;
				box-sizing: border-box;
			}

			.treaty-head-text {
				height: calc(100% - 40px);
				overflow: auto;
				font-size: 14px;
				color: #666;
				padding: 0 10px;
				line-height: 1.8;
				-webkit-overflow-scrolling: touch;
			}
		}

		.head-title {
			font-weight: bold;
			font-size: 24px;
			line-height: 35px;
			text-align: center;
			color: #333;
			margin-bottom: 5px;
		}

		.head-text {
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: #666;
			
			&.text-color{
				color: #FF6969;
				margin-top: 10px;
			}
		}

		.middle-item {
			position: relative;

			input {
				width: 100%;
				border: 0;
				font-size: 16px;
				line-height: 25px;
				color: #333;
				padding: 5px 5px 5px;
				margin-top: 20px;
				border-bottom: 1px solid #eee;
				box-sizing: border-box;
				position: relative;
				z-index: 10;
				background-color: transparent;

				&:focus {
					border-bottom-color: #3377ff;
				}

				&[readonly]:focus {
					border-bottom-color: #eee;
				}
			}

			.middle-item-right {
				position: absolute;
				right: 5px;
				top: 50%;
			}
		}

		.middle-item-radio {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px;
			padding-top: 20px;
			border-bottom: 1px solid #eee;

			.item-radio-text {
				font-size: 0.16rem;
				line-height: 0.25rem;
				color: #666;
			}
		}

		.line-color {
			border-bottom: 1px solid #3377ff;
		}

		.middle-item-bottom {
			font-size: 14px;
			line-height: 21px;
			color: #666;
			padding-top: 20px;

			.agree-text {
				color: #666;
			}

			.agree-protocol {
				color: #3377ff;
			}
		}

		.footer {
			padding-top: 40px;
			display: flex;

			.van-button {
				border-radius: 8px;
				font-size: 18px;

				&:first-child {
					margin-right: 10px;
				}
			}
		}
	}
</style>