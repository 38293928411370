import store from '@/store/store'

const keep = (page = []) => {
	return {
		beforeRouteEnter(to, from, next) {
			if (page.indexOf(from.name) === -1) {
				store.commit('keep/removeKeep', to.name);
			}

			setTimeout(() => next(), 50);
		},
		beforeRouteLeave(to, from, next) {

			if (page.indexOf(to.name) === -1) {
				store.commit('keep/removeKeep', from.name);
			} else {
				store.commit('keep/setKeep', from.name);
			}

			setTimeout(() => next(), 50);
		}
	}
}

export default keep
