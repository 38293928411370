<template>
	<div class="register">
		<loading-page :loading="loadingPage"></loading-page>
		<div class="nav">
			<div :class="['nav-item', { 'nav-item-active': step == item.id }]" v-for="(item, index) in stepData">
				<div class="nav-item-top">{{ item.id }}</div>
				<div class="nav-item-text">{{ item.title }}</div>
			</div>
		</div>
		<register-district v-if="step == 1" @success="districtSuccess"></register-district>
		<register-code v-if="step == 2" @success="codeSuccess"></register-code>
		<register-info v-if="step == 3" @success="infoSuccess"></register-info>
		<!-- <register-discern v-if="step == 3" @success="discernSuccess"></register-discern> -->
	</div>
</template>

<script>
	import keepMiXin from '@/mixins/keep';
	import UserApi from '@/api/user';
	import VantVendor from '@/vendor/vant';
	import ValidatorVendor from '@/vendor/validator';
	import LoadingPage from '@/components/LoadingPage';
	import RegisterDistrict from '@/views/register/components/RegisterDistrict.vue';
	import RegisterCode from '@/views/register/components/RegisterCode.vue';
	import RegisterInfo from '@/views/register/components/RegisterInfo.vue';
	import RegisterDiscern from '@/views/register/components/RegisterDiscern.vue';
	
	export default {
		name: 'Register',
		mixins: [keepMiXin(['RegisterNotice'])],
		data() {
			return {
				step: 1,
				stepData: [{
						id: 1,
						title: '选择区县'
					},{
						id: 2,
						title: '验证号码'
					},
					{
						id: 3,
						title: '完善信息'
					},
					// { id: 3, title: '人脸识别' }
				],
				userInfo: {
					name: '',
					idCard: '',
					telephone: '',
					userPhoto: '',
					districtId: 0,
					departmentId: 0,
					roleType: 1
				},
				loadingPage: true,
				isRegisterComplete: false
			};
		},
		created() {
			UserApi.getUserInfo()
				.then(result => {
					this.userInfo.name = result.data.name;
					this.userInfo.idCard = result.data.id_card;
					this.userInfo.telephone = result.data.telephone;
					this.userInfo.userPhoto = result.data.user_photo;
					this.userInfo.districtId = result.data.district_id;
					this.userInfo.departmentId = result.data.department_id;
					this.userInfo.roleType = result.data.role_type
					this.loadingPage = false;
					
					if (ValidatorVendor.checkValue('isEmpty', this.userInfo.telephone)) {
						return this.step = 1;
					}
					
					if (this.userInfo.roleType == 1) {
						return this.step = 3;
					}
					
					this.isRegisterComplete = true;
					
					VantVendor.Dialog.alert({
						title: '提示',
						message: '您已经注册完成啦'
					}).then(() => {
						this.$router.replace({
							name: 'Index'
						});
					});
				})
				.catch(error => {
					console.log(error);
				});
		},
		methods: {
			districtSuccess(){
				this.step = 2
			},
			codeSuccess(data) {
				this.userInfo.telephone = data.phone;
				this.step = 3;
			},
			infoSuccess(data) {
				this.userInfo.name = data.name;
				this.userInfo.idCard = data.user_photo;
				this.userInfo.districtId = data.district_id;
				this.userInfo.departmentId = data.department_id;
				
				this.isRegisterComplete = true;

				VantVendor.Dialog.alert({
					title: '提示',
					message: '恭喜您注册成功'
				}).then(() => {
					setTimeout(() => this.$router.replace({
						name: 'RegisterWelcome'
					}), 300);
				});
			},
			// discernSuccess(data) {
			// 	this.isRegisterComplete = true;
			// 	this.userInfo.userPhoto = data.user_photo;

			// 	VantVendor.Dialog.alert({
			// 		title: '成功',
			// 		message: '恭喜您注册成功'
			// 	}).then(() => {
			// 		setTimeout(() => this.$router.replace({
			// 			name: 'Index'
			// 		}), 300);
			// 	});
			// }
		},
		beforeRouteLeave(to, from, next) {
			if (this.isRegisterComplete == false && to.name != 'RegisterNotice') {
				VantVendor.Dialog.confirm({
					title: '提示',
					message: '您还未注册完成',
					confirmButtonText: '继续注册',
					cancelButtonText: '下次再说',
					closeOnPopstate: false
				}).then(() => {
					next(false);
				}).catch(() => {
					next();
				});
			} else {
				next();
			}
		},
		components: {
			LoadingPage,
			RegisterCode,
			RegisterInfo,
			RegisterDiscern,
			RegisterDistrict
		}
	};
</script>

<style lang="less" scoped>
	.register {
		background: #fff;
		padding-top: 30px;
		box-sizing: border-box;

		.nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 50px 30px;
			box-sizing: border-box;
			position: relative;

			&::before {
				content: '';
				width: 220px;
				height: 1px;
				position: absolute;
				top: 10px;
				left: 50%;
				transform: translate(-50%);
				background-color: #f2f2f2;
			}

			.nav-item {
				position: relative;
				z-index: 10;

				&.nav-item-active {
					.nav-item-top {
						color: #fff;
						background-color: #3377ff;
					}

					.nav-item-text {
						color: #3377ff;
					}
				}

				.nav-item-top {
					width: 20px;
					height: 20px;
					border-radius: 50%;
					font-size: 12px;
					line-height: 20px;
					text-align: center;
					color: #777;
					background: #f2f2f2;
					margin: 0 auto;
					box-sizing: border-box;
				}

				.nav-item-text {
					font-size: 14px;
					line-height: 1;
					color: #666;
					margin-top: 10px;
				}
			}
		}

		.footer-btn {
			width: 100%;
			padding: 0 10px;
			position: fixed;
			bottom: 0;
			box-sizing: border-box;

			.van-button {
				border-radius: 8px;
			}
		}
	}
</style>