<template>
	<div class="register-discern">
		<div class="head-picture"><img src="@/assets/img/test245.png" alt="" /></div>
		<div class="head-title">人脸识别</div>
		<div class="head-text">
			首次参加活动必需进行人脸识别认证（只需进行一次），我们将对数据严格保密，请放心验证，认证通过后才能报名。
		</div>
		<div class="footer">
			<van-button type="primary" color="#3377FF" plain round block @click="onBackStep">
				上一步
			</van-button>
			<van-uploader :after-read="onSelectImage" capture="camera">
				<van-button type="primary" round color="#3377FF" block>开始认证</van-button>
			</van-uploader>
		</div>
	</div>
</template>

<script>
import UploadApi from '@/api/upload';
import IndexApi from '@/api/index';
import VantVendor from '@/vendor/vant';

export default {
	name: 'RegisterDiscern',
	data() {
		return {
			userPhoto: ''
		};
	},
	methods: {
		onSelectImage(image) {
			UploadApi.image(image.file)
				.then(result => {
					this.userPhoto = result.data.fileUrl;
					this.faceVerify();
				})
				.catch(error => {
					console.log(error);
				});
		},
		faceVerify() {
			let params = {
				user_photo: this.userPhoto
			};

			VantVendor.Toast.loading('认证中');

			IndexApi.registerFaceVerify(params)
				.then(result => {
					this.$store.commit('keep/clearKeep');
					this.$store.commit('district/setDistrict', result.data);
					VantVendor.Toast.clear();
					this.$emit('success', params);
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		},
		onBackStep() {
			this.$parent.step = 2;
		}
	}
};
</script>

<style lang="less" scoped>
.register-discern {
	background-color: #fff;
	padding: 20px 30px;
	box-sizing: border-box;

	.head-picture {
		width: 100px;
		height: 100px;
		margin-left: 50%;
		transform: translateX(-50%);
		padding-top: 40px;
		margin-bottom: 31px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.head-title {
		font-weight: bold;
		font-size: 20px;
		line-height: 29px;
		text-align: center;
		color: #333;
		padding-bottom: 17px;
		margin-bottom: 7px;
		border-bottom: 1px solid #ddd;
	}

	.head-text {
		font-size: 16px;
		line-height: 26px;
		color: #666;
		padding: 10px 15px;
		text-align: center;
	}

	.footer {
		padding-top: 40px;
		display: flex;

		.van-uploader {
			flex: 1;
			display: block;

			.van-button {
				width: 100%;
				border-radius: 8px;
			}

			/deep/.van-uploader__input-wrapper {
				width: 100%;
			}
		}

		.van-button {
			flex: 1;
			border-radius: 8px;
			font-size: 18px;
			margin-right: 10px;
			padding: 0;
		}
	}
}
</style>
