<template>
	<div class="register-code">
		<div class="register-code">
			<div class="register-code-title">请输入手机号</div>
			<div class="register-code-input">
				<input type="text" v-model="telephone" placeholder="请输入手机号" />
			</div>
			<div class="register-code-input">
				<input type="number" v-model="verifyCode" placeholder="请输入验证码" />
				<button
					class="register-code-input-verify"
					:disabled="waitCodeIng"
					@click="sendSmsCode"
				>
					<template v-if="waitCodeIng == false">
						获取验证码
					</template>
					<template v-else>
						重新获取（{{ waitCodeTime }}s）
					</template>
				</button>
			</div>
			<div class="register-code-telephone-button">
				<van-button type="primary" color="#3377FF" plain round block @click="$parent.step = 1">
					上一步
				</van-button>
				<van-button type="primary" round color="#3377FF" block @click="checkVerifyCode">
					下一步
				</van-button>
			</div>
		</div>
	</div>
</template>

<script>
import IndexApi from '@/api/index';
import VantVendor from '@/vendor/vant';
import ValidatorVendor from '@/vendor/validator';

export default {
	data() {
		return {
			verifyCode: '',
			validator: null,
			telephone: '',
			waitCodeIng: false,
			waitCodeTime: 0,
			countdown: null
		};
	},
	created() {
		this.telephone = this.$parent.userInfo.telephone;
		this.validator = new ValidatorVendor({
			rules: {
				phone: {
					required: true,
					mobile: true
				},
				code: {
					length: 6,
					required: true
				}
			},
			messages: {
				phone: {
					mobile: '手机号格式错误',
					required: '手机号不能为空'
				},
				code: {
					length: '验证码为6位数字',
					required: '验证码不能为空'
				}
			}
		});
	},
	watch: {
		waitCodeIng(newVal) {
			if (newVal == true) {
				this.countdown = setInterval(() => {
					this.waitCodeTime--;

					if (this.waitCodeTime <= 0) {
						clearInterval(this.countdown);
						this.waitCodeIng = false;
					}
				}, 1000);
			}
		}
	},
	destroyed(to, from, next) {
		clearInterval(this.countdown);
	},
	methods: {
		sendSmsCode() {
			VantVendor.Toast.loading('加载中');

			let params = {
				type: 'reg',
				phone: this.telephone,
				code: this.verifyCode,
			};
			
			if (!this.validator.checkFields(params, ['telephone'])) {
				return;
			}

			IndexApi.registerSmsCode(params)
				.then(result => {
					VantVendor.Toast.success('发送成功');
					this.waitCodeTime = 60;
					this.waitCodeIng = true;
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		},
		checkVerifyCode() {

			let params = {
				type: 'reg',
				phone: this.telephone,
				code: this.verifyCode,
			};
			
			if (!this.validator.checkData(params)) {
				return;
			}

			VantVendor.Toast.loading('加载中');

			IndexApi.registerCheckCode(params)
				.then(result => {
					VantVendor.Toast.clear();
					clearInterval(this.countdown);
					this.$emit('success', params);
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		}
	}
};
</script>

<style lang="less" scoped>
.register-code {
	padding-bottom: 30px;
	box-sizing: border-box;

	.register-code-title {
		font-size: 24px;
		color: #333;
		text-align: center;
		line-height: 35px;
	}

	.register-code {
		padding: 30px 30px 20px;

		.register-code-input {
			position: relative;
			margin-top: 30px;

			input {
				display: block;
				width: 100%;
				border: none;
				margin: 0;
				padding: 5px;
				height: 35px;
				line-height: 35px;
				font-size: 16px;
				border-bottom: 1px solid #f2f2f2;
				box-sizing: border-box;

				&:focus {
					border-bottom-color: #3377ff;
				}
			}

			.register-code-input-verify {
				position: absolute;
				right: 0;
				top: 5px;
				border: none;
				background-color: transparent;
				font-size: 16px;
				color: #666;
				color: #3377ff;
				line-height: 20px;
				background-color: #fff;

				&[disabled] {
					color: #999;
				}
			}
		}

		.register-code-telephone-button {
			width: 100%;
			padding-top: 50px;
			box-sizing: border-box;
			display: flex;

			.van-button {
				border-radius: 8px;
				font-size: 16px;
				
				&:first-child {
					margin-right: 10px;
				}
			}
		}
	}
}
</style>
