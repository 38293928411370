<template>
	<div class="RegisterDistrict">
		<div class="container">
			<div class="container-title">选择区县</div>
			<div class="container-item" @click="isAreaShow = true">
				<input type="text" v-model="areaText" placeholder="请选择区县" readonly />
			</div>
			<div class="container-button">
				<van-button type="primary" round color="#3377FF" block @click="toRegisterCode">
					下一步
				</van-button>
			</div>
		</div>
		<van-popup v-model="isAreaShow" position="bottom">
			<van-picker show-toolbar value-key="title" :columns="areaList" @confirm="onConfirm"
				@cancel="isAreaShow = false" />
		</van-popup>
	</div>
</template>

<script>
import IndexApi from '@/api/index';
import VantVendor from '@/vendor/vant';
import ValidatorVendor from '@/vendor/validator';
import ActionVendor from "@/vendor/action"

export default {
	data() {
		return {
			isAreaShow: false,
			areaText: '',
			areaId: '',
			areaList: [{
				id: 1,
				type: 1,
				title: '上饶市'
			}, {
				id: 2,
				type: 2,
				title: '信州区'
			}, {
				id: 3,
				type: 2,
				title: '广丰区'
			}, {
				id: 4,
				type: 2,
				title: '广信区'
			}, {
				id: 5,
				type: 2,
				title: '玉山县'
			}, {
				id: 7,
				type: 2,
				title: '横峰县'
			}, {
				id: 8,
				type: 2,
				title: '弋阳县'
			}, {
				id: 6,
				type: 2,
				title: '铅山县'
			}, {
				id: 13,
				type: 2,
				title: '德兴市'
			}, {
				id: 12,
				type: 2,
				title: '婺源县'
			}, {
				id: 11,
				type: 2,
				title: '万年县'
			}, {
				id: 9,
				type: 2,
				title: '余干县'
			}, {
				id: 10,
				type: 2,
				title: '鄱阳县'
			}, {
				id: 15,
				type: 2,
				title: '经开区'
			}, {
				id: 14,
				type: 2,
				title: '三清山'
			}]
		};
	},
	created() {
		
	},
	methods: {
		onConfirm(index, key) {
			this.areaText = index.title
			this.areaId = index.id
			this.isAreaShow = false
		},
		toRegisterCode(){
			if(this.areaId == ''){
				return VantVendor.Toast('请选择区县');
			}
			if(this.areaId == 2){
				return ActionVendor.jumpArea()
			}
			this.$emit('success');
		}
	}
};
</script>

<style lang="less" scoped>
.RegisterDistrict {
	padding-bottom: 30px;
	box-sizing: border-box;

	.container-title {
		font-size: 24px;
		color: #333;
		text-align: center;
		line-height: 35px;
	}

	.container {
		padding: 30px 30px 20px;

		.container-item {
			position: relative;
			margin-top: 30px;

			input {
				display: block;
				width: 100%;
				border: none;
				margin: 0;
				padding: 5px;
				height: 35px;
				line-height: 35px;
				font-size: 16px;
				border-bottom: 1px solid #f2f2f2;
				box-sizing: border-box;

				&:focus {
					border-bottom-color: #3377ff;
				}
			}

			.register-code-input-verify {
				position: absolute;
				right: 0;
				top: 5px;
				border: none;
				background-color: transparent;
				font-size: 16px;
				color: #666;
				color: #3377ff;
				line-height: 20px;
				background-color: #fff;

				&[disabled] {
					color: #999;
				}
			}
		}

		.container-button {
			width: 100%;
			padding-top: 50px;
			box-sizing: border-box;

			.van-button {
				border-radius: 8px;
				font-size: 16px;
			}
		}
	}
}
</style>
